<template>
  <!-- Version: 1.6 -->
  <h2>1. Game Description</h2>
  <p>
    The Highlight Games Soccerbet Game is a unique software simulation of randomly selected football highlights from
    matches to create gaming opportunities.
  </p>
  <h2>2. Game Description and Length</h2>
  <p>
    A single round of an electronic betting game is between 3 to 5 minutes in length. This consists of the fixture
    announcement, game week, preamble, in-game display, and results. The in-game display will be 1 minute in length.
  </p>
  <p>
    One whole season can run anywhere from 78 minutes up to 190 minutes to complete. This is because of the number of
    teams in that specific league; therefore, the number of game weeks will vary.
  </p>
  <p>
    A selection of highlight football events is statistically independent, random, and unpredictable, and generated by
    the Random Number Generator – RNG.
  </p>
  <h2>3. Betting Markets</h2>
  <h3>3.1 Exact Goals</h3>
  <p>
    The meaning of Exact Number of Goals Betting is that an exact number of goals must be scored in a single match. So,
    this betting variation means that you are to predict a precise number of goals despite a final outcome of a whole
    match.
  </p>
  <h3>3.2 Home Team Exact Goals</h3>
  <p>A bet on the exact number of goals that the home team will score in the game.</p>
  <h3>3.3 Away Team Exact Goals</h3>
  <p>A bet on the exact number of goals that the away team will score in the game.</p>
  <h3>3.4 Both Teams to Score</h3>
  <p>
    Both teams need to score at least one goal, making any result from 1-1 and upwards, a winning bet. The “Both Teams
    to Score” bet gives punters the chance to take advantage of such events, in order to get profitable odds, when only
    one of the two teams has managed to score.
  </p>
  <h3>3.5 Correct Score</h3>
  <p>
    Correct score gambling is betting on what you think the final outcome of a football match will be with a maximum of
    6 goals in a game there are 28 possible outcomes.
  </p>
  <h3>3.6 Double Chance</h3>
  <p>
    A double chance bet allows you to cover two of the three possible outcomes in a football match with one bet. Home
    team and draw - Your bet is a winner if the home team wins or draws the match. Away team and draw - Your bet is a
    winner if the away team wins or draws the match.
  </p>
  <h3>3.7 Match Result (1X2)</h3>
  <p>A bet on home team winning (1), away team winning (2) or match ending with a draw (X).</p>
  <h3>3.8 Match Result and Both Teams to Score (1X2 + BTTS)</h3>
  <p>A bet on the result of the match (1 is home win, X is draw, 2 is away team win) and if both teams score or not.</p>
  <ul>
    <li>1 + Goal - Home team to win and both teams to score at least one goal [e.g., 3-1].</li>
    <li>1 + No Goal - Home team to win and is the only team to score a goal [e.g., 1-0].</li>
    <li>X + Goal - Bet on a draw and both team’s score. [e.g., 1-1, 2-2].</li>
    <li>X + No Goal – Bet on a draw with no team score. [Only 0-0].</li>
    <li>2 + Goal - Away team to win and both teams to score at least one goal [e.g., 1-2, 1-3].</li>
    <li>2 + No Goal - Away team wins and is the only team to score goals [e.g., 0-1, 0-2].</li>
  </ul>
  <h3>3.9 Match Result and Over/Under 1.5 Goals</h3>
  <p>A bet on the result of the match from 1X2 and the total number of goals to be under or over 1.5 goals.</p>
  <h3>3.10 Match Result and Over/Under 2.5 Goals</h3>
  <p>A bet on the result of the match from 1X2 and the total number of goals to be under or over 2.5 goals.</p>
  <h3>3.11 Total Goals (1 to 2, 1 to 3, 1 to 4, 2 to 3, 2 to 4)</h3>
  <p>
    A bet that the game will have either 1 to 2,1 to 3, 1 to 4, 2 to 3 or 2 to 4 total goals as the final result. This
    is a YES or NO bet.
  </p>
  <h3>3.12 Over/Under 1.5 Home Team Goals</h3>
  <p>A bet that the Home team will score either more or less than 1.5 goals in the match.</p>
  <h3>3.13 Over/Under 1.5 Away Team Goals</h3>
  <p>A bet that the Away team will score either more or less than 1.5 goals in the match.</p>
  <h3>3.14 Over/Under 1.5, 2.5, 3.5, 4.5 Total Goals</h3>
  <p>A bet on the total number of goals scored by both teams to be under or over 1.5, 2.5, 3.5 or 4.5 in the match.</p>
</template>
